import request from '@/utils/request'

// 用户列表
export function userList (params) {
  return request.post('/api/userList', params)
}

// 解绑用户实名
export function removeRealCheck (params) {
  return request.post('/api/removeRealCheck', params)
}

// 解绑用户绑定银行卡
export function removeBindCard (params) {
  return request.post('/api/removeBindCard', params)
}

// 编辑用户手机号
export function editUserMobile (params) {
  return request.post('/api/editUserMobile', params)
}

/**
 * 修改用户信息
 */
export function editUserInfo (params) {
  return request.post('/api/updateUserInfo', params)
}

/**
 * 获取用户bindId
 */
export function getBindId(params) {
  return request.post('/api/preCheckUserInfo', params)
}