<template>
  <div>
    <el-dialog
      title="user_no"
      width="600px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="200px" size="small" :rules="rules">
        <el-form-item
          label="当前user_no"
          label-width="100px"
          style="margin-bottom: 15px"
        >
        {{ info.user_no }}
        </el-form-item>
        <el-form-item
          label="修改为"
          label-width="100px"
          prop="user_no"
          style="margin-bottom: 15px"
        >
          <el-input
            v-model="form.user_no"
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        

        <el-form-item
          label="当前idCard"
          label-width="100px"
          style="margin-bottom: 15px"
        >
        {{ info.id_card_no }}
        </el-form-item>
        <el-form-item
          label="修改为"
          label-width="100px"
          prop="idCard"
          style="margin-bottom: 15px"
        >
          <el-input
            v-model="form.idCard"
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm('form')" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { editUserInfo } from '@/api/h5User.js'

export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {
        userId: "",
        user_no: "",
        idCard: ''
      },
      rules:{
        user_no: [
            { validator: true, trigger: 'blur' }
        ],
        idCard: [
            { validator: true, trigger: 'blur' }
        ],
      },
      info: {}
    };
  },
  methods: {
    async isShow(row) {
      this.dialogFormVisible = true;
      this.info = row
      this.form.userId = row.user_id
    },
    confirm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const {code} = await editUserInfo(this.form)
          if(code == 200){
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.close()
          }
        } else {
          return false;
        }
      });
    },
    close() {
      this.form = {
        user_no:"",
        idCard: ""
      }
      this.$refs.form.resetFields();
      this.dialogFormVisible = false;
      this.$emit("ok")
    },
    handleClose(done) {
      done();
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .customW {
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>