<template>
  <div>
    <div class="search-bar">
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="80px"
        size="small"
      >
        <div class="search-group-wrap flex">
          <el-row class="flex-1">
            <el-col :span="6">
              <el-form-item label="用户姓名">
                <el-input
                  v-model="searchForm.username"
                  placeholder="请输入姓名"
                  clearable
                  @keydown.enter.native="serchData"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="手机号">
                <el-input
                  v-model="searchForm.mobile"
                  placeholder="请输入手机号"
                  clearable
                  @keydown.enter.native="serchData"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="身份证号">
                <el-input
                  v-model="searchForm.idCardNo"
                  placeholder="请输入身份证号"
                  clearable
                  @keydown.enter.native="serchData"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="inben ml_20">
            <el-button type="primary" size="small" @click="serchData"
              >搜索</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <base-table
      :columns="userlCol"
      :data="tableData"
      :pagination="searchForm"
      :total="total"
      @getData="initData"
      :loading="loading"
      wrapperHeight="calc(100% - 45px)"
    >
      <el-table-column
        slot="operation"
        label="操作"
        align="center"
        fixed="right"
        v-if="managerType != 0 || getAuth('editUser')"
      >
        <template slot-scope="scope">
          <!-- v-if="managerType != 0 || getAuth('adminOperate')" -->
          <el-button type="text" size="small" v-if="(managerType != 0 || getAuth('editUser'))" @click="editMobile(scope.row)">修改手机号</el-button>
          <el-button type="text" size="small" v-if="scope.row.id_card_image_url" @click="checkIdCardImg(scope.row)"
            >查看身份证照片</el-button
          >
          <el-button type="text" v-if="(managerType != 0 || getAuth('editUser')) && scope.row.real_auth_status == 1" size="small" @click="removeRealCheck(scope.row)"
            >解绑实名</el-button
          >
          <el-button type="text" v-if="(managerType != 0 || getAuth('editUser')) && scope.row.is_bind_card == 1" size="small" @click="removeBindCard(scope.row)"
            >解绑银行卡</el-button
          >
          <el-button type="text" size="small" @click="modifyInfo(scope.row)"
            >修改用户信息</el-button
          >
        </template>
      </el-table-column>
    </base-table>
    <el-dialog width="600px" :visible.sync="showIdCardImg">
      <div class="id-card-img">
        <img width="100%" :src="idCardImg" alt />
      </div>
    </el-dialog>
    <edit-mobile ref="editMobile" @ok="initData()"/>
    <modify-user ref="modifyUser"></modify-user>
  </div>
</template>

<script>
import { removeBindCard , getBindId} from '@/api/h5User.js'
import columns from "./columns";
import { userList, removeRealCheck } from '@/api/h5User.js'
import { getAuth } from "@/utils/index.js";
import editMobile from './components/edit-mobile.vue';
import modifyUser from './components/modify-user.vue'


export default {
  components: { 
    editMobile,
    modifyUser
  },
  name: 'h5UserList',

  data() {
    return {
      managerType: localStorage.getItem("managerType"),
      getAuth: getAuth,
      ...columns,
      tableData:[],
      loading:false,
      total:0,
      searchForm:{
        page: 1,
        per_page: 10,
      },
      showIdCardImg:false,
      idCardImg:"",
      bindId: ""
    };
  },

  mounted() {
    this.initData()
  },

  methods: {
    serchData(){
      this.searchForm.page = 1
      this.searchForm.per_page = 10
      this.initData()
    },
    async initData(){
      const {code,data} = await userList(this.searchForm)
      if(code == 200){
        this.tableData = data.list.map((item) => {
          item.real_auth_status_text = item.real_auth_status == 1 ? "是" : "否";
          item.is_bind_card_text = item.is_bind_card == 1 ? "是" : "否";
          return item;
        });
        this.total = data.total
      }
    },
    editMobile(row){
      this.$refs.editMobile.isShow(row)
    },
    checkIdCardImg(row){
      this.showIdCardImg = true
      this.idCardImg = row.id_card_image_url
    },
    removeRealCheck(row){
      this.$confirm(`是否对${row.real_name}解除实名？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const parmes = {
            userId: row.user_id,
          };
          const {code} = await removeRealCheck(parmes)
          if(code == 200){
            this.initData()
            this.$message({
              type: "success",
              message: "解绑成功!",
            });
          }
        })
        .catch(() => {});
    },
    modifyInfo(row) {
      console.log(row)
      this.$refs.modifyUser.isShow(row)
    },
    removeBindCard(row){
      this.$confirm(`是否解除${row.real_name}绑定的银行卡？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (!row.user_no) {
            this.$message.error("当前用户的userNo为空不能解绑")
            return
          }
          const res = await getBindId({
            userNo: row.user_no
          })
          if (res.code == 200) {
            if (res.data.bindCardList) {
              this.bindId = JSON.parse(res.data.bindCardList)[0].bindId
            } else {
              this.$message.error("未查询到当前用户的bindID")
              return
            }
          } else {
            this.$message.error("未查询到当前用户的bindCard信息")
            return
          }
          const parmes = {
            userId: row.user_id,
            cardId:row.card_list[0].card_id
          };
          if (this.bindId) {
            parmes.bindId = this.bindId
            parmes.userNo = res.data.userNo
          }
          const {code} = await removeBindCard(parmes)
          if(code == 200){
            this.initData()
            this.$message({
              type: "success",
              message: "解绑成功!",
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.id-card-img{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>