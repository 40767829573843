export const userlCol = [
  {
    label: "用户编号",
    prop: 'user_no',
    align: 'center',
  },
  {
    label: "用户姓名",
    prop: 'real_name',
    align: 'center',
  },
  {
    label: "手机号",
    prop: 'mobile',
    align: 'center',
  },
  {
    label: "是否实名",
    prop: 'real_auth_status_text',
    align: 'center',
  },
  {
    label: "身份证号码",
    prop: 'id_card_no',
    align: 'center',
  },
  {
    label: "是否绑卡",
    prop: 'is_bind_card_text',
    align: 'center',
  },
  {
    label: "操作",
    slot: 'operation'
  },
]
export default {
  userlCol,
}